import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, LinearProgress, Skeleton, Tooltip } from "@mui/material";
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../components/FormikControl/FormikControl';

import { Edit} from '@mui/icons-material';
import { Alert, Snackbar } from '@mui/material'
import systemMasterServices from '../systemMasterServices';




export default function EmployeeMasterForm({masterName,refreshList, AppAlert, data=null}) {

    const [formValues, setFormValues] = useState(null);
    const [dialogStatus, setDialogStatus] = useState(false);
    const [savingInProgress, setSavingStatus] = useState(false);


    const [snackStatus, setSnackStatus] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackType, setSnackType] = useState("error");


    const initialValues = {
        title: "",
        state:"",
        address:"",
        masterName:masterName
        
    }

    const onSubmit = (values, onSubmitProps) => {
        setSavingStatus(true)
        systemMasterServices.save(values).then(res =>{
            refreshList();
            AppAlert(res.msg)
            setDialogStatus(false);
        }).catch(error=>{
            AppAlert(error.msg,"error")
        }).finally(()=>{
            setSavingStatus(false);
        })
    }

    const validationRule = Yup.object({
        title: Yup.string().required("Required"),
    });

    const addNew = ()=>{
        let autoFill = {
            title:"",
            masterName:masterName
        }
        if(masterName === "branch"){
            autoFill = {
                title:"",
                state:"",
                address:"",
                masterName:masterName
            }
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const updateData = ()=>{

        let autoFill = {
            id:data.id,
            title:data.title,
            masterName:masterName
        }
        if(masterName === "branch"){
            autoFill = {
                id:data.id,
                title:data.title,
                state:data.state,
                address:data.address,
                masterName:masterName
            }
        }
        setFormValues(autoFill);
        setDialogStatus(true)
    }

    const handleClose  = () =>{
        setDialogStatus(false);
    }


    return (
      <>
          {!data && <button className="btn btn-primary btn-sm" onClick={addNew}> Add New</button>
          
          
          
          }
          {data && 
            <>
            
            
                <Tooltip title="Update">
                    <IconButton onClick={updateData} style={{padding:"0px"}}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
            
            </>
         }

        
            <Snackbar open={snackStatus} onClose={ ()=>{setSnackStatus(false)} } autoHideDuration={10000}  anchorOrigin={{ vertical: 'top', horizontal: 'center',  }}>
                <Alert  severity={snackType} sx={{ width: '100%' }}> {snackMessage}</Alert>
            </Snackbar>

            <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                aria-describedby="alert-dialog-slide-description"
            >

                <Formik
                    initialValues={formValues || initialValues}
                    validationSchema={validationRule}
                    onSubmit={onSubmit}
                    enableReinitialize validateOnMount
                >
                    {
                        (formik) => {
                            //console.log(formik.values);
                            return (
                                <Form>
                                    <DialogTitle>{"Manage"} {masterName}</DialogTitle>
                                    <DialogContent>
                                        <div className="row p-2">
                                            
                                            

                                                    <FormikControl control="input" formik={formik} 
                                                        label="Title" name="title" 
                                                        className="col-sm-12 col-xs-12 p-2" 
                                                    />

                                                    {masterName === "branch" && 
                                                        <>
                                                            <FormikControl control="input" formik={formik}
                                                                label="State" name="state" 
                                                                className="col-sm-12 col-xs-12 p-2" 
                                                            />
                                                            <FormikControl control="input" formik={formik}
                                                                label="address" name="address" 
                                                                className="col-sm-12 col-xs-12 p-2" 
                                                            />
                                                        </>
                                                    }

                                            
                                        </div>

                                        <div className="row">
                                        {   /*JSON.stringify(formik.values) */}
                                        </div>

                                        {savingInProgress && <div className="row"><LinearProgress/></div> }
                                    </DialogContent>
                                    <DialogActions>
                                        {!savingInProgress && 
                                            <>
                                            <button className="btn btn-success" type="submit">Save</button>
                                            <button className="btn btn-danger" type="button" onClick={handleClose}>Cancel</button>
                                            </>
                                        }
                                        
                                    </DialogActions>
                                </Form>
                            )
                        }
                    }
                </Formik>
            </Dialog>
      </>
    )
}