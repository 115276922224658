import React from 'react'
import Footer from './Footer'
import Header from './Header'

export default function AppLayout({children}) {

    
    
  return (
    <>
        <Header/>
            <div className="main" >
                {children}
            </div>
        <Footer/>

    </>
  )
}
