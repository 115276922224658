import React, { useCallback, useEffect, useState } from 'react'
import { Avatar, Chip, CircularProgress, Dialog,  DialogContent, DialogTitle, List, ListItem, ListItemButton, Stack, TextField } from '@mui/material';
import { ContactPhone } from '@mui/icons-material';
import { ErrorMessage } from 'formik'

export default function SearchSelect(props) {
  const {label,name,className, inputLabel, asyncSearch, formik} = props;


  const [dialogStatus,setDialogStatus] = useState(false);
  const [optionList, setOptionList] = useState(null)
  const [searchProgress, setSearchProgress] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [queryText,setQueryText] =  useState("");
  
  
  const autoFillData = useCallback( () =>{
    let val = formik.values[name];
    if(val !== undefined && val !== ""){
        asyncSearch("?id="+val).then(res=>{
          setOptionList(res.data);
          setSelectedOption([res.data[0]])
        })
    }
  },[])

  useEffect(()=>{
    autoFillData();
  },[])


  const handleClose = () =>{
    setDialogStatus(false);
  }


  const search = (e) =>{
    //console.log("Search for Source "+ queryText);
    let q = `?firstname=${queryText}`
    //console.log("Search for Source "+ q);
    
    setSearchProgress(true);
    

    asyncSearch(q).then(res=>{
        setSearchProgress(false)
        setOptionList(res.data);
    })
  }

  const selectOption = (option) =>{ 
    setSelectedOption([option])
    setDialogStatus(false);
    formik.setFieldValue(name, option.id);
  }

  const removeSelectedOption = (option) =>{
    let newSelectedOption = selectedOption.filter( o => {
        return o.id !== option.id;
    })
    if(newSelectedOption.length <=0) { formik.setFieldValue(name, "")}
    setSelectedOption(newSelectedOption);
  }

  
  return (
    <>
      <div className={className}>
            <div className={`card p-2`} >
              <Stack direction="row" spacing={2} >
                {
                  selectedOption.length > 0 ?
                          selectedOption.map((option) => {
                            
                                return (
                                    <div key={option.id}>
                                    <Chip avatar={<Avatar >{option.firstName.charAt(0).toUpperCase()}</Avatar>} 
                                        label={ option.firstName+" "+option.lastName} variant="outlined" key={option.id}
                                        onDelete={() => {
                                            removeSelectedOption(option)
                                            }
                                        }
                                    />
                                    </div>
                                )
                            })
                  :
                    <button type="button" className="btn col-12 btn-block" onClick={() => setDialogStatus(true)}>Select {label}</button>
                }
              </Stack>
            </div>
            <ErrorMessage name={name}>{errorMsg => <span className="text-danger small">{errorMsg} </span>}</ErrorMessage>
      </div>


      <Dialog
                open={dialogStatus}
                keepMounted
                
                onClose={handleClose} 
            >
                <DialogTitle>Search {label}</DialogTitle>
                <DialogContent>

                    <>
                        
                        <TextField className="mt-2" 
                            onKeyUp={ (e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    search();
                                }
                            } }  
                            value={queryText}  
                            onChange={ (e)=> { setQueryText(e.target.value)}} label="Search"
                        />

                        {searchProgress &&
                            <div className="row">
                                <div className="text-center mt-2">
                                    <CircularProgress/>
                                </div>
                            </div>
                        }
                        {
                            optionList &&
                            <List>
                                {
                                    optionList.map((option, index) => {
                                        return (
                                            <ListItem key={option.id}>
                                                
                                                    <ListItemButton onClick={() => { selectOption(option) }}>
                                                        {
                                                          inputLabel.map((label,index)=> {
                                                            return (
                                                              <span key={index}>
                                                                {label === "phone" ?
                                                                  <>
                                                                    <ContactPhone className="ms-2 me-2"/> {option[label]+" "}
                                                                  </>
                                                                  :
                                                                  <>{option[label]+" "}</>
                                                                
                                                                }
                                                                
                                                              </span>
                                                            )
                                                          })
                                                        }
                                                    </ListItemButton>
                                                
                                            </ListItem>
                                        )
                                    })
                                }
                            </List>
                        }

                    </>


                </DialogContent>
                
            </Dialog>
    </>
  )
}
