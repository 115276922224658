import {useEffect, useState} from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { startSession } from '../../redux/authStore';

import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../components/FormikControl/FormikControl';

import authServices from '../authServices';
import { Link } from 'react-router-dom';


const AdminLogin = (props) => {

  const [error,setError] = useState(false);
  const [errorMsg,setErrorMsg] = useState("");

  const initialValues = {
    username :'',
    password:'',
  }

  const validationRule = Yup.object({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });

  const navigate = useNavigate();
  const { startSession } = props;
 
  useEffect(()=>{  
    if(localStorage.authUser){
      startSession();
      navigate("/");
    }
  },[])

  //[startSession,navigate]

  const submotLoginForm = (values, onSubmitProps) => {
    authServices.attemptLogin(values).then( (res) => {
      startSession();
      navigate("/");
    }).catch( error =>{
      setError(true);
      setErrorMsg(error.message);
      //console.log(error);
    })
  }  

  return (
    <>
      <div className="login-bg overlay-bg">
        <div className="row justify-content-center">
          <div className="col-md-6">
            
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 d-flex justify-content-center">
                    <span className="uniworldvision-logo"></span>
                  </div>
                  <div className="col-md-12 d-flex justify-content-between">
                  </div>
                </div>
              </div>
              <div className="card-body">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationRule}
                  onSubmit={submotLoginForm}
                  enableReinitialize validateOnMount
                >
                  {
                    (formik) => {
                      //console.log(formik.values);
                      return (
                        <Form>
                          {error && 
                            <div className="col-md-12">
                              <div className="alert alert-danger">
                                <strong>!</strong> {errorMsg}
                              </div>
                            </div>
                          }

                          <FormikControl control="input" formik={formik} type="text"
                            label="Username" name="username"
                            className="col-sm-12 col-xs-12 mt-4"
                          />

                          <FormikControl control="input" formik={formik} type="password"
                            label="Password" name="password"
                            className="col-sm-12 col-xs-12 mt-4"
                          />

                          {/* <Link to="/auth/register" className="btn btn-register">Register</Link> */ }
                          <button type="submit" className="btn btn-primary mt-4 float-end btn-lg">Submit</button>
                        </Form>
                      )
                    }
                  }
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


const mapStateToProps = (state) =>{
  return {
    auth:state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startSession : () => {
      dispatch( startSession());
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);