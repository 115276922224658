import React,{useState} from 'react'
import { Dialog, DialogActions, DialogContent,  IconButton, Tooltip } from "@mui/material";
import { Delete, Warning } from '@mui/icons-material';


export default function ConfirmDialog({id, confirm,children, title}) {
    const [dialogStatus, setDialogStatus] = useState(false);
    const handleClose = () =>{
        setDialogStatus(false)
    }
    return (
    <>  
        {title != undefined ?
            <button type="button" className="btn btn-danger btn-sm" onClick={()=>setDialogStatus(true)} style={{padding:"2px"}}>
                {title}
            </button>
            :
            <Tooltip title="Remove">
                <IconButton onClick={()=>setDialogStatus(true)} style={{padding:"0px"}}>
                    <Delete/>
                </IconButton>
            </Tooltip>

        }

        
        
        
        <Dialog
                open={dialogStatus}
                keepMounted
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                aria-describedby="alert-dialog-slide-description"
        >

            <DialogContent> <Warning/> {children}</DialogContent>
            <DialogActions>
                <button type="button" onClick={()=>{confirm(id);handleClose()} } className="btn btn-danger">Yes</button>
                <button type="button" onClick={handleClose} className="btn btn-success">No</button>
            </DialogActions>
        </Dialog>
    </>
    )
}
