import api,{authAPI} from "../api/api"



const getUserList = () => {
    return new Promise((resolve, reject)=>{
          authAPI.get("user").then( (res)=>{
            if(res.data){
                resolve(res.data);
            } else {
                reject({
                    message: "Somthing Went Wrong"
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const getUserDD = () => {
    return new Promise((resolve, reject)=>{
          authAPI.get("user/dd").then( (res)=>{
            if(res.data){
                resolve(res.data);
            } else {
                reject({
                    message: "Somthing Went Wrong"
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}


const getUserRoleDD = () => {
    return new Promise((resolve, reject)=>{
          authAPI.get("user-role/dd").then( (res)=>{
            if(res.data){
                resolve(res.data);
            } else {
                reject({
                    message: "Somthing Went Wrong"
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}




const getUserRoleList = () => {
    return new Promise((resolve, reject)=>{
          authAPI.get("user-role").then( (res)=>{
            if(res.data){
                resolve(res.data);
            } else {
                reject({
                    message: "Somthing Went Wrong"
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const saveUser = (payload) =>{
    return new Promise((resolve, reject)=>{
        authAPI.post("/user",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}

const saveUserRole = (payload) =>{
    return new Promise((resolve, reject)=>{
        authAPI.post("/user-role",payload).then( (res)=>{
            if(!res.data.error){
                resolve(res.data);
            } else {
                reject({
                    message: res.data.msg
                })
            }
          }).catch( (error) => {
            reject({
                message: "Something Went Wrog",
                error : error
            })
          })
    })
}



const userServices = {
    getUserList,
    getUserRoleList,

    getUserDD,
    getUserRoleDD,

    saveUser,
    saveUserRole,
 }
 
 export default userServices;